import Index from '@/views/pc/pub/Index.vue';
import HomeMain from '@/views/pc/pub/home/Main.vue';

export default [{
        path: "/",
        component: Index,
        // 匹配成功，则children的component 会被渲染在的 <router-view> 中
        children: [{
            path: "",
            name: "Main",
            component: HomeMain
        }, {
            path: "home",
            name: "HomeMain",
            component: HomeMain
        }, {
            path: "list_article",
            name: "ListArticle",
            component: () => import("@/views/pc/pub/article/List.vue")
        }, {
            path: "view_article",
            name: "ViewArticle",
            component: () => import("@/views/pc/pub/article/View.vue")
        }, {
            path: "about_us",
            name: "AboutUs",
            component: () => import("@/views/pc/pub/about_us/Home.vue")
        }]
    }, {
        path: "/login",
        name: "Login",
        component: () => import("@/views/pc/pub/user/Login.vue")
    }, {
        path: "/pc/login",
        name: "PCLogin",
        component: () => import("@/views/pc/pub/user/Login.vue")
    },


]