/* export default {
    websiteTitle: "CMS系统，让共享信息更便捷", //网站标题
    copyrightParty: "cms制作团队", //版权方
    version: "v0.0.1",
    apiServer: "http://localhost:8999"
} */
export default {
    websiteTitle: "东荣 - 不忘初心，再创辉煌", //网站标题
    copyrightParty: "东荣五金制品公司", //版权方
    version: "v0.0.1",
    apiServer: "http://api.dongrong668.com"
}