import AdminHome from '@/views/pc/admin/Home.vue';

export default [{
        path: "/admin",
        name: "Admin",
        component: AdminHome,
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/carousel/list",
        name: "ListCarousel",
        component: () => import("@/views/pc/admin/carousel/List.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/carousel/add",
        name: "AddCarousel",
        component: () => import("@/views/pc/admin/carousel/Add.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/carousel/edit",
        name: "EditCarousel",
        component: () => import("@/views/pc/admin/carousel/Edit.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/logo/edit",
        name: "EditLogo",
        component: () => import("@/views/pc/admin/logo/Edit.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/footer/list",
        name: "ListFooter",
        component: () => import("@/views/pc/admin/footer/List.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/footer/add",
        name: "AddFooter",
        component: () => import("@/views/pc/admin/footer/Add.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/footer/edit",
        name: "EditFooter",
        component: () => import("@/views/pc/admin/footer/Edit.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/profile/edit",
        name: "EditProfile",
        component: () => import("@/views/pc/admin/profile/Edit.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/history/edit",
        name: "EditHistory",
        component: () => import("@/views/pc/admin/history/Edit.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/timeline/list",
        name: "ListTimeline",
        component: () => import("@/views/pc/admin/timeline/List.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/timeline/add",
        name: "AddTimeline",
        component: () => import("@/views/pc/admin/timeline/Add.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/timeline/edit",
        name: "EditTimeline",
        component: () => import("@/views/pc/admin/timeline/Edit.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/news/list",
        name: "ListNews",
        component: () => import("@/views/pc/admin/news/List.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/news/add",
        name: "AddNews",
        component: () => import("@/views/pc/admin/news/Add.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/news/edit",
        name: "EditNews",
        component: () => import("@/views/pc/admin/news/Edit.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/news/view",
        name: "ViewNews",
        component: () => import("@/views/pc/admin/news/View.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/notice/list",
        name: "ListNotice",
        component: () => import("@/views/pc/admin/notice/List.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/notice/add",
        name: "AddNotice",
        component: () => import("@/views/pc/admin/notice/Add.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/notice/edit",
        name: "EditNotice",
        component: () => import("@/views/pc/admin/notice/Edit.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/notice/view",
        name: "ViewNotice",
        component: () => import("@/views/pc/admin/notice/View.vue"),
        meta: {
            requiresAuth: true
        }
    }, {
        path: "/pc/admin/category/list",
        name: "ListCategory",
        component: () => import("@/views/pc/admin/category/List.vue"),
        meta: {
            requiresAuth: true
        }
    }



]