<template>
	<div>
		<el-backtop></el-backtop>
		<el-container>
			<el-header class="header_nav">
				<!-- 头部导航栏组件 -->
				<div style="max-width:1200px;margin:10px auto;">
					<div class="header_logo" style="">
						<img crossorigin :src="logo.image_url" style="width:60px;height:60px;float: left;" alt="logo" />
						<div style="float: left;line-height: 60px;margin-left:10px;">
							<span>{{ logo.motto }}</span>
						</div>
					</div>
					<div style="float:right">
						<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" ref="header_el_menu">
							<el-menu-item class="el-menu-item" v-for="(item, item_index) in navList" :key="item_index">
								<el-menu-item :index="item.label">{{ item.label }}</el-menu-item>
							</el-menu-item>
						</el-menu>
					</div>
				</div>
			</el-header>
			<el-main class="home_main">
				<!-- 首页主要内容 -->
				<router-view />
			</el-main>
			<div style="background-color: #c8daf1;">
				<!-- 底部导航栏组件 -->
				<div style="height:200px;">
					<el-row style="max-width:1200px;margin: auto;">
						<el-col :span="8">
							<p><a style="font-weight: bold;">快速通道</a></p>
							<p v-for="item in footerList" :key="item.label">
								<a
									class="a-pointer"
									style="font-size:small"
									v-if="item.type === 'left'"
									:href="item.target_url"
									:target="item.new_page ? '_blank' : '_self'"
									>{{ item.label }}</a
								>
							</p>
						</el-col>
						<el-col :span="8">
							<p><a style="font-weight: bold;">智能办公</a></p>
							<p v-for="item in footerList" :key="item.label">
								<a
									class="a-pointer"
									style="font-size:small"
									v-if="item.type === 'middle'"
									:href="item.target_url"
									:target="item.new_page ? '_blank' : '_self'"
									>{{ item.label }}</a
								>
							</p>
						</el-col>
						<el-col :span="8">
							<p v-for="item in footerList" :key="item.label">
								<a
									class="a-pointer"
									style="font-size:small"
									v-if="item.type === 'right' && item.target_url != ''"
									:href="item.target_url"
									:target="item.new_page ? '_blank' : '_self'"
									>{{ item.label }}</a
								>
								<span v-else-if="item.type === 'right'" style="font-size:small">{{ item.label }}</span>
							</p>
						</el-col>
					</el-row>
				</div>
				<div style="text-align: center;">
					<el-row style="max-width:1200px;margin: auto;border-top: 1px solid #a7a1a1;padding: 6px;">
						<span style="color:#9e9e9e;font-size: 12px;">
							版权所有 © 2020-{{ date.getFullYear() + " " + base.copyrightParty }} , 保留一切权利
						</span>
					</el-row>
				</div>
			</div>
		</el-container>
	</div>
</template>

<script>
import base from "@/base.js"
import { apiGetLogo } from "@/api/pub/logo.js"
import { apiListCategory } from "@/api/pub/category.js"
import { apiListActiveFooter } from "@/api/pub/footer.js"

export default {
	//声明数据源
	data() {
		return {
			base: base,
			logo: {
				image_url: require("@/assets/logo.png"),
				motto: ""
			},
			activeIndex: this.$route.query.nav ? this.$route.query.nav : "首页",
			navList: [],
			footerList: [],
			date: new Date()
		}
	},
	//定义方法
	methods: {
		// 获取logo
		async getLogo() {
			try {
				const response = await apiGetLogo()
				//console.log(response);
				if (response.data.code == 200) {
					let logo = response.data.data
					if (logo != null) {
						this.logo = logo
						this.logo.image_url = this.base.apiServer + this.logo.image_url
					}
					//console.log(this.logo);
				} else {
					this.$message.error(response.data.message)
				}
			} catch (error) {
				console.log(error)
			}
		},
		// 获取头部导航栏
		async listCategory() {
			try {
				const response = await apiListCategory()
				//console.log(response);
				if (response.data.code == 200) {
					let categoryList = response.data.data
					this.navList = [{ label: "首页" }].concat([{ label: "产品中心" }], categoryList, [{ label: "关于我们" }])
				}
			} catch (error) {
				console.log(error)
			}
		},
		//获取页脚
		async listFooter() {
			try {
				const response = await apiListActiveFooter()
				//console.log(response);
				if (response.data.code == 200) {
					this.footerList = response.data.data
				} else {
					this.$message.error(response.data.message)
				}
			} catch (error) {
				console.log(error)
			}
		},
		handleSelect(key, keyPath) {
			//console.log(key, keyPath);
			if (key !== null) {
				//window.location.href = "../#" + key;
				//console.log(this.$route);
				this.activeIndex = key.toString()
				switch (key) {
					case "产品中心":
						window.location.href = "http://eshop.dongrong668.com"
						//window.open("http://eshop.dongrong668.com")
						break
					case "新闻资讯":
						this.$router.push("/list_article?type=新闻资讯&nav=" + key.toString())
						//window.location.href="";
						break
					case "通知公告":
						this.$router.push("/list_article?type=通知公告&nav=" + key.toString())
						//window.location.href="";
						break
					case "关于我们":
						this.$router.push("/about_us?nav=" + key.toString())
						break
					default:
						this.$router.push("/home?nav=" + key.toString())
						break
				}
			}
		},
		updateActiveIndex() {
			this.activeIndex = this.$route.query.nav ? this.$route.query.nav : "首页"
			//console.log(this.activeIndex);
		}
	},
	mounted() {
		//页面渲染完成调用方法获取数据
		this.getLogo()
		this.listCategory()
		this.listFooter()
		//console.log("route>>", this.$route);
		//console.log("activeIndex>>", this.activeIndex);
	}
}
</script>
<style lang="scss" scoped>
//头部导航栏样式start
.header_logo {
	height: 60px;
	vertical-align: middle;
	overflow: hidden;
	float: left;
	text-align: left;
}
.el-menu.el-menu--horizontal {
	border-bottom: none;
}
.el-menu-item {
	border-bottom: 2px solid #409eff;
	font-size: large;
}
//头部导航栏样式end

.el-main {
	padding: 0px;
}
.el-footer {
	padding: 0px;
}
</style>
<style lang="scss">
.header_nav {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}
body {
	margin: 0px;
}

.home_main {
	margin-top: 20px;
}

a {
	text-decoration: none;
	color: #000000;
}
.a-pointer:hover {
	cursor: pointer;
}
</style>
