<template>
	<div>
		<el-menu default-active="" @open="handleOpen" @close="handleClose" background-color="#e3e9f1" text-color="#000" active-text-color="#1e82e8">
			<div v-for="(item, index) in sideBarData" :key="index">
				<el-submenu v-if="item.children" :index="index + '-' + item.url">
					<template slot="title">
						<i :class="item.icon"></i>
						<span slot="title">{{ item.label }}</span>
					</template>
					<el-menu-item-group>
						<el-menu-item
							v-for="(subItem, subIndex) in item.children"
							:key="subIndex"
							:index="index + '-' + subIndex + '-' + subItem.url"
							@click.native="clickSidebarMenu(subItem.label, subItem.url, subItem.open_tab)"
						>
							<i :class="subItem.icon"></i>
							<span slot="title">{{ subItem.label }}</span>
						</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				<el-menu-item v-else :index="index + '-' + item.url" @click.native="clickSidebarMenu(item.label, item.url, item.open_tab)">
					<i :class="item.icon"></i>
					<span slot="title">{{ item.label }}</span>
				</el-menu-item>
			</div>
		</el-menu>
	</div>
</template>

<script>
export default {
	data() {
		return {
			sideBarData: [
				{
					label: "首页设置",
					icon: "el-icon-s-home",
					children: [
						{
							label: "轮播图",
							url: "/pc/admin/carousel/list",
							icon: "el-icon-picture",
							open_tab: true
						},
						{
							label: "LOGO",
							url: "/pc/admin/logo/edit",
							icon: "el-icon-orange",
							open_tab: true
						},
						{
							label: "管理页脚",
							url: "/pc/admin/footer/list",
							icon: "el-icon-more",
							open_tab: true
						}
					]
				},
				{
					label: "企业形象",
					icon: "el-icon-monitor",
					children: [
						{
							label: "公司概况",
							url: "/pc/admin/profile/edit",
							icon: "el-icon-office-building",
							open_tab: true
						},
						{
							label: "关于我们",
							url: "/pc/admin/history/edit",
							icon: "el-icon-school",
							open_tab: true
						},
						{
							label: "发展历程",
							url: "/pc/admin/timeline/list",
							icon: "el-icon-s-marketing",
							open_tab: true
						}
					]
				},
				{
					label: "资讯管理",
					url: "",
					icon: "el-icon-document-copy",
					children: [
						{
							label: "新闻资讯",
							url: "/pc/admin/news/list",
							icon: "el-icon-notebook-2",
							open_tab: true
						},
						{
							label: "通知公告",
							url: "/pc/admin/notice/list",
							icon: "el-icon-s-check",
							open_tab: true
						},
						{
							label: "管理分类",
							url: "/pc/admin/category/list",
							icon: "el-icon-folder-opened",
							open_tab: true
						}
					]
				},
				{
					label: "系统设置",
					url: "/admin/system_settings",
					icon: "el-icon-s-tools",
					open_tab: true,
					children: null
				}
			]
		};
	},
	//定义方法
	methods: {
		handleOpen(key, keyPath) {
			//console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			//console.log(key, keyPath);
		},
		clickSidebarMenu(label, url, open_tab) {
			//console.log(label, url);
			this.$parent.openTab(label, url, open_tab);
		}
	}
};
</script>

<style lang="scss" scoped></style>
