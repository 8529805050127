<template>
	<div>
		<div class="block">
			<el-carousel trigger="click" height="25vw" :interval="play_interval" indicator-position="outside">
				<el-carousel-item v-for="item in carouselList" :key="item.id">
					<a :href="item.target_url" :target="item.new_page ? '_blank' : '_self'">
						<img crossorigin :src="base.apiServer + item.image_url" style="width:100%;height:25vw" />
					</a>
				</el-carousel-item>
			</el-carousel>
		</div>
		<el-row style="max-width:1200px;margin: auto;">
			<el-col :span="6">
				<div class="block" style="padding-top:10px;width:300px;">
					<el-carousel height="174px" :interval="play_interval - 1000" indicator-position="outside">
						<el-carousel-item v-for="item in focusArticle" :key="item.id">
							<a @click="JumpTo('/view_article?nav=' + item.category.label + '&id=' + item.id)">
								<img crossorigin :src="base.apiServer + item.focus_image_url" style="width:100%;height:150px" />
							</a>
							<div class="focusArticleTitle" @click="JumpTo('/view_article?nav=' + item.category.label + '&id=' + item.id)">
								{{ item.title }}
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="grid-content bg-purple">
					<table class="news_table">
						<tr>
							<td><a @click="JumpTo('/list_article?nav=新闻资讯')">新闻速递</a></td>
							<td>
								<a @click="JumpTo('/list_article?nav=新闻资讯')"><i class="el-icon-more"></i></a>
							</td>
						</tr>
						<tr v-for="item in newsList" :key="item.title">
							<td>
								<a @click="JumpTo('/view_article?nav=新闻资讯&id=' + item.id)">{{ item.title }}</a>
							</td>
							<td>
								<a @click="JumpTo('/view_article?nav=新闻资讯&id=' + item.id)">{{ item.release_time.substring(0, 10) }}</a>
							</td>
						</tr>
					</table>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="grid-content bg-purple-light">
					<table class="notice_table">
						<tr>
							<td><a @click="JumpTo('/list_article?nav=通知公告')">通知公告</a></td>
							<td>
								<a @click="JumpTo('/list_article?nav=通知公告')"><i class="el-icon-more"></i></a>
							</td>
						</tr>
						<tr v-for="item in noticeList" :key="item.title">
							<td>
								<a @click="JumpTo('/view_article?nav=通知公告&id=' + item.id)">{{ item.title }}</a>
							</td>
							<td>
								<a @click="JumpTo('/view_article?nav=通知公告&id=' + item.id)">{{ item.release_time.substring(0, 10) }}</a>
							</td>
						</tr>
					</table>
				</div>
			</el-col>
		</el-row>
		<div style="background-color: #f6faff;margin-top: 20px;">
			<el-row style="max-width:1200px;margin: auto;text-align: center;">
				<el-col :span="24">
					<h3><span>公司概况</span> / Company Profile</h3>
				</el-col>
			</el-row>
			<el-row style="max-width:1200px;margin: auto;padding-bottom: 50px;">
				<el-col :span="8"><img crossorigin style="width:380px;" :src="base.apiServer + profile.image_url"/></el-col>
				<el-col :span="16">
					<div style="padding-top: 15px; line-height: 25px; text-align: justify;" v-html="profile.text"></div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import base from "@/base.js";
import { apiListActiveCarousel } from "@/api/pub/carousel.js";
import { apiListArticle } from "@/api/pub/article.js";
import { apiGetProfile } from "@/api/pub/profile.js";

export default {
	data() {
		return {
			base: base,
			carouselList: [],
			play_interval: 5000,
			focusArticle: [],
			newsList: [],
			noticeList: [],
			profile: {
				id: 0,
				title: "",
				content: "",
				image_url: ""
			}
		};
	},
	methods: {
		// 获取轮播图
		async listActiveCarousel() {
			try {
				const response = await apiListActiveCarousel();
				//console.log(response);
				if (response.data.code == 200) {
					this.carouselList = response.data.data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		// 获取文章列表
		async listArticle() {
			try {
				let formData = new FormData();
				formData.append("start", 0);
				formData.append("limit", 5);
				formData.append("title", "");
				formData.append("text", "");
				formData.append("status", 1);
				formData.append("is_focus", true);
				formData.append("start_time", new Date(0, 1));
				formData.append("end_time", new Date(9999, 1));
				/* console.log("===========formData===========");
				for (var [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				//获取焦点文章
				const response = await apiListArticle(formData);
				if (response.data.code == 200) {
					this.focusArticle = response.data.data.articleList;
					//console.log("focusArticle>>", this.focusArticle);
				} else {
					console.error("异常>>", response.data);
					this.$message.error(response.data.message);
				}

				formData.delete("is_focus");
				//获取新闻咨询
				formData.append("category_label", "新闻资讯");
				const newsResponse = await apiListArticle(formData);
				if (newsResponse.data.code == 200) {
					this.newsList = newsResponse.data.data.articleList;
					//console.log("newsList>>", this.newsList);
				} else {
					console.error("异常>>", newsResponse.data);
					this.$message.error(newsResponse.data.message);
				}

				//获取通知公告
				formData.set("category_label", "通知公告");
				const noticeResponse = await apiListArticle(formData);
				//console.log(noticeResponse);
				if (noticeResponse.data.code == 200) {
					this.noticeList = noticeResponse.data.data.articleList;
					//console.log("noticeList>>", this.noticeList);
				} else {
					console.error("异常>>", noticeResponse.data);
					this.$message.error(noticeResponse.data.message);
				}
			} catch (error) {
				console.error("异常>>", error);
			}
		},
		// 获取介绍
		async getProfile() {
			try {
				const response = await apiGetProfile();
				//console.log(response);
				if (response.data.code == 200) {
					this.profile = response.data.data;
					//console.log(this.profile);
				} else {
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		},
		JumpTo(url) {
			//console.log(url);
			this.$router.push(url);
		}
	},
	mounted() {
		//页面渲染完成调用方法获取数据
		this.listActiveCarousel();
		this.listArticle();
		this.getProfile();
	}
};
</script>

<style lang="scss" scoped>
.focusArticleTitle {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 300px;
}
.focusArticleTitle:hover {
	cursor: pointer;
}
.news_table,
.notice_table {
	background-color: #ffffff;
	padding-left: 10px;
	width: 100%;
	table-layout: fixed;
}

.news_table a,
.notice_table a {
	color: #3b3b3b;
}
.news_table tr:first-child a,
.notice_table tr:first-child a {
	color: #000000;
	font-weight: bold;
	font-size: large;
}
.news_table td,
.notice_table td {
	padding: 5px;
}
.news_table td:first-child {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.news_table td:last-child {
	padding-right: 50px;
	text-align: right;
	width: 100px;
}
.notice_table td:first-child {
	width: 190px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.notice_table td:last-child {
	text-align: right;
	width: 100px;
}
a:hover {
	cursor: pointer;
}
</style>
