import axios from "@/api/baseRequest.js";

//根据ID获取文章
export const apiGetArticleById = function (id) {
    return axios.get("/api/v1/pub/article/get_by_id", {
        params: {
            "id": id
        }
    });
}

//文章列表
export const apiListArticle = (formData) => axios.post("/api/v1/pub/article/list_by_condition", formData);